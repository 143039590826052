import React, { useCallback } from 'react'
import getJson from '@/utils/fetch/getJson'

export type UseFetchReturnProps<T> = {
    data?: T
    error?: Error
    fetchData: () => void
    loading: boolean
}

const useFetch = <T>(url?: string): UseFetchReturnProps<T> => {
    const [data, setData] = React.useState<T | undefined>()
    const [error, setError] = React.useState<Error | undefined>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const fetchComponentData = useCallback((): void => {
        if (url) {
            setLoading(true)
            setError(undefined)
            getJson(url)
                .then(setData)
                .catch(setError)
                .finally(() => setLoading(false))
        }
    }, [url])

    React.useEffect(() => {
        fetchComponentData()
    }, [fetchComponentData])

    return {
        data,
        error,
        fetchData: fetchComponentData,
        loading,
    }
}

export default useFetch
