import React, { memo, useMemo, VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { TeamStandingsClientModel } from '@sport1/types/sportData'
import { GenericComponentProps } from '@sport1/types/web'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { DisplayProp } from '@sport1/news-styleguide/Types'
import { StandingWithPointsDeductionLabel } from './utils/standingsDataState/types'
import StandingsList from './List'
import StandingsQualifyInfos from './QualifyInfos'
import useFetch from '@/hooks/useFetch'
import StandingsError from '@/components/Standings/Error'
import { getMatchLivetickerHref } from '@/utils/navigation/Navigator'
import { useNavigation } from '@/utils/navigation/Standard'
import { useLivetickerMatch } from '@/utils/liveticker/LivetickerMatchProvider'
import PlaceHolderBox from '@/components/PlaceHolderBox'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'
import Fallback from '@/components/Fallback'

export const REWORKED_STANDINGS = [
    sportIdentifiers.soccer.name,
    sportIdentifiers.darts.name,
    sportIdentifiers.handball.name,
    sportIdentifiers.field_hockey.name,
    sportIdentifiers.indoor_volleyball.name,
    sportIdentifiers.basketball.name,
    sportIdentifiers.baseball.name,
    sportIdentifiers.american_football.name,
    sportIdentifiers.icehockey.name,
]

type StandingsProps = Pick<GenericComponentProps, 'contentUrl'> & {
    layoutMode?: 'RIGHT_COLUMN' | 'RESPONSIVE'
    display?: DisplayProp['display']
}

const Standings: VFC<StandingsProps> = ({ contentUrl, layoutMode = 'RESPONSIVE', display }) => {
    const { error, data, loading } = useFetch<TeamStandingsClientModel>(contentUrl)
    const { match } = useLivetickerMatch()
    const { navigation } = useNavigation()

    const filteredStandings = useMemo(() => {
        if (layoutMode === 'RESPONSIVE' || !match || !(match.homeTeam && match.awayTeam)) {
            return data?.standings
        }
        const {
            homeTeam: { id: homeTeamId },
            awayTeam: { id: awayTeamId },
        } = match
        return data?.standings?.flatMap(standing => {
            if (
                standing.teams?.some(
                    ({ team }) => team?.id === homeTeamId || team?.id === awayTeamId
                )
            ) {
                return [standing]
            }
            return []
        })
    }, [match, layoutMode, data])

    if (loading || (!error && !data)) {
        return <PlaceHolderBox height={500} />
    }

    if (!data?.standings?.length) {
        return <Fallback text="Keine Tabelle verfügbar." />
    }

    if (error) {
        return <StandingsError />
    }

    return (
        <NonFlexingContainer
            backgroundColor="pearl"
            testID={`standings-${data?.competition?.name}`}
            display={display}
            spaceBottom="standard"
        >
            {layoutMode === 'RIGHT_COLUMN' ? (
                <NonFlexingContainer marginBottom="spacing-6">
                    <CardHeadline
                        type="DEFAULT"
                        text="Tabelle"
                        href={
                            match
                                ? getMatchLivetickerHref({
                                      match,
                                      tag: navigation?.tag,
                                      page: 'tabelle',
                                  })
                                : undefined
                        }
                        imageUrl={navigation?.tag?.parent ? navigation?.tag?.iconUrl : undefined}
                    />
                </NonFlexingContainer>
            ) : null}
            {filteredStandings?.map((standing: StandingWithPointsDeductionLabel, index) => {
                return (
                    <StandingsList
                        key={`standings-${data?.competition?.id}-${index}`}
                        paddingBottom={
                            index < filteredStandings.length - 1 ? 'spacing-7' : 'spacing-none'
                        }
                        standing={standing}
                        competition={data?.competition}
                        contextIds={[match?.homeTeam?.id ?? '', match?.awayTeam?.id ?? '']}
                        layoutMode={layoutMode}
                    />
                )
            })}
            <StandingsQualifyInfos qualifyInfos={data?.qualifyInfos} />
        </NonFlexingContainer>
    )
}

export default memo(Standings)
